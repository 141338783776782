import Vue from 'vue'
import VueRouter from 'vue-router'
import isAuthorised from "@/router/middleware/is-authorised";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      redirect: '/login'
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
        middleware: [ isAuthorised ]
      },
    },
    {
      path: '/applications',
      name: 'Applications',
      component: () => import('@/views/reports/Applications.vue'),
      meta: {
        pageTitle: 'New Applications Report',
        breadcrumb: [
          {
            text: 'New Applications',
            active: true,
          },
        ],
        middleware: [ isAuthorised ]
      },
    },
    {
      path: '/reapplications',
      name: 'ReApplications',
      component: () => import('@/views/reports/Reapplications.vue'),
      meta: {
        pageTitle: 'Re-Applications Report',
        breadcrumb: [
          {
            text: 'Re-Applications',
            active: true,
          },
        ],
        middleware: [ isAuthorised ]
      },
    },
    {
      path: '/course-leaders-report',
      name: 'CLReport',
      component: () => import('@/views/reports/CLReport.vue'),
      meta: {
        pageTitle: 'Course Leaders Reports',
        breadcrumb: [
          {
            text: 'CL Reports',
            active: true,
          },
        ],
        middleware: [ isAuthorised ]
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/views/SettingsPage.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
        middleware: [ isAuthorised ]
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next
  }
  return middleware[0]({
    ...context
  })
})

export default router
