export default {
  _apiUrl: process.env.VUE_APP_TOKEN_URL,
  // Endpoints
  get loginEndpoint() {
    return `${this._apiUrl}token/`
  },
  get registerEndpoint() {
    return `${this._apiUrl}token/`
  },
  get refreshEndpoint() {
    return `${this._apiUrl}token/refresh/`
  },
  get logoutEndpoint() {
    return `${this._apiUrl}token/logout/`
  },

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
