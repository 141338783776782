export class ApiServiceAbstract {
  http;

  constructor(http) {
    this.http = http;
  }

  async request(promiseRequest, ...args) {
    return new Promise((async (resolve, reject) => {
      try {
        const {data} = await promiseRequest(...args);
        resolve(data)
      } catch (e) {
        reject(e);
      }
    }))
  }
}
