import {ApiServiceAbstract} from "@/services/api.service.abstract";

export class UserService extends ApiServiceAbstract {
  constructor(http) {
    super(http);
  }

  getUser() {
    return this.request(this.http.get, 'statistic_dashboard/user_info/');
  }
}
