import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import router from './router'
import store from './store'
import App from './App.vue'
import VueMoment from 'vue-moment'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Moment JS
Vue.use(VueMoment)

// Cookies
Vue.use(VueCookies)

// Vue Meta
Vue.use(VueMeta)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
