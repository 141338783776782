import axiosIns from '@axios'
import { UserService } from '@/services/user.service'

export default {
  namespaced: true,

  state: {
    user: null,
  },

  getters: {
    user: ({ user }) => user,
  },

  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },

  actions: {
    async loadUser({ commit }) {
      const userService = new UserService(axiosIns)
      try {
        const userInfo = await userService.getUser()
        userInfo.login = userInfo.username.split('@')[0]
        commit('setUser', userInfo)
        return userInfo
      } catch (e) {
        commit('setUser', null)
      }
    },
  },
}
